export default [
    {
        text: "Bestellungen",
        icon: "assets/menu-order-document.svg",
        path: "/schulte/orders"
    },
    {
        text: "Anfragen",
        icon: "assets/menu-product-request.svg",
        path: "/schulte/requested-offers"
    },
    {
        text: "Abholtermine",
        icon: "assets/menu-appointment.svg",
        path: "/schulte/appointments"
    }
];
