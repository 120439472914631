export default [
    {
        text: "Test",
        icon: "assets/menu-company.svg",
        path: "/test/layout"
    },
    {
        text: "Plantafel",
        icon: "assets/menu-timeline.svg",
        path: "/planning/timeline"
    },
    {
        text: "Bestellungen",
        icon: "assets/menu-timeline.svg",
        path: "/schulte/orders"
    },
    {
        text: "Landkarte",
        icon: "assets/menu-geo-map.svg",
        path: "/geo/map"
    },
    {
        text: "Projekte",
        icon: "assets/menu-projects.svg",
        items: []
    },
    {
        text: "Belege",
        icon: "assets/menu-voucher.svg",
        items: []
    },
    {
        text: "Stammdaten",
        icon: "assets/menu-masterdata.svg",
        items: [
            {
                text: "Material",
                icon: "assets/menu-material.svg",
                path: "/masterdata/materials"
            },
            {
                text: "Leistungen",
                icon: "assets/menu-service.svg",
                path: "/masterdata/services"
            },
            {
                text: "Kunden",
                icon: "assets/menu-customer.svg",
                path: "/masterdata/customers"
            },
            {
                text: "Lieferanten",
                icon: "assets/menu-supplier.svg",
                path: "/masterdata/suppliers"
            },
            {
                text: "Hersteller",
                icon: "assets/menu-manufacturer.svg",
                path: "/masterdata/manufacturers"
            },
            {
                text: "Fahrzeuge",
                icon: "assets/menu-car.svg",
                path: "/masterdata/mobile-units"
            },
            {
                text: "Werkzeug",
                icon: "assets/menu-tool.svg",
                path: "/masterdata/tools"
            },
            {
                text: "Kostenstellen",
                icon: "assets/menu-costcenter.svg",
                path: "/masterdata/costcenters"
            },
            {
                text: "Mitarbeiter",
                icon: "assets/menu-employee.svg",
                path: "/masterdata/employees"
            }
        ]
    },
    {
        text: "Grundlagen",
        icon: "assets/menu-basedata.svg",
        items: [
            {
                text: "Benutzergruppen",
                icon: "assets/menu-usergroup.svg",
                path: "/basedata/usergroups"
            },
            {
                text: "Steuersätze",
                icon: "assets/menu-vat.svg",
                path: "/basedata/vat"
            },
            {
                text: "Nummernkreise",
                icon: "assets/menu-number-range.svg",
                path: "/basedata/number-ranges"
            },
            {
                text: "Abteilungen",
                icon: "assets/menu-department.svg",
                path: "/basedata/departments"
            },
            {
                text: "Reportdesigner",
                icon: "assets/menu-report-designer.svg",
                path: "/basedata/report-designer"
            },
            {
                text: "Unternehmen",
                icon: "assets/menu-company.svg",
                path: "/basedata/company"
            }
        ]
    }
];
