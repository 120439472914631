import appConfig from "@/app-config";
import apiRequest from "@/utils/apirequest";

import navigation from '@/app-navigation';
import navigation2 from '@/views/schulte/schulte-navigation';

export default {


    setMenu() {

        if (appConfig.compId === 3)
            appConfig.menu = navigation2;
        else
            appConfig.menu = navigation;
    },

    processAuthData(data) {

        appConfig.sessionToken = data.token;
        appConfig.userId = data.user.id;
        appConfig.userIdent = data.user.displayname;
        appConfig.email = data.user.email;
        appConfig.userGroupId = data.user.usergroup_id;
        appConfig.compId = data.user.comp_id;
        appConfig.menu = data.user.comp_id;
        this.setMenu();

        // appConfig.adminUser = data.user.is_admin_user;
        // appConfig.employeeId = data.user.employee_id;

        // data.rights.forEach((right) => {
        //     appConfig.rights.push(parseInt(right.id));
        // });

        localStorage.setItem('sessionToken', appConfig.sessionToken);
    },


    loggedIn() {
        return appConfig.sessionToken && appConfig.sessionToken !== '';
    },

    async authenticate() {
        const me = this;

        appConfig.sessionToken = localStorage.getItem('sessionToken');
        if (!appConfig.sessionToken) return false;
        // @todo optimize this. not on every request etc.

        return await apiRequest.get("auth/check", {},
            function (response) {
                if (response.status === 200) {
                    me.processAuthData(response.data);
                    return true;
                } else {
                    me.clearLoginData();
                    return false;
                }
            },
            function () {
                me.clearLoginData();
                return false;
            });
    },

    async logIn(company, loginName, password) {
        const me = this;
        return apiRequest.post("auth/login", {
                client_type: 'FRONTEND',
                comp_id: company,
                username: loginName,
                password: password
            },
            {
                'Authorization': null
            },
            function (response) {
                if (response.status === 200) {
                    me.processAuthData(response.data);
                    return true;
                } else {
                    return false;
                }
            },
            function () {
                return false;
            });
    },

    clearLoginData() {
        appConfig.sessionToken = null;
        appConfig.compId = null;
        appConfig.userId = null;
        appConfig.userIdent = null;
        appConfig.email = null;
        appConfig.userGroupId = null;
        appConfig.adminUser = null;
        appConfig.employeeId = null;
        appConfig.menu = null;
        localStorage.removeItem('sessionToken');
    },

    async logOut() {
        let me = this;
        return apiRequest.delete('auth/logout').then(
            function () {
                me.clearLoginData();
                location.href = '/';
            },
            function () {
                me.clearLoginData();
                location.href = '/';
            }
        );
    },

    async changePw(pw) {
        let me = this;
        let result = false;
        await apiRequest.patch("v1/renew_password", {password: pw}, {},
            function (response) {
                if (response.status === 200) {
                    result = {
                        isOk: true
                    }
                }
            },
            function (error) {
                me._error = error;
                result = {
                    isOk: false
                }
            });

        return result;
    },

    async createAccount(email, password) {
        try {
            // Send request
            console.log(email, password);

            return {
                isOk: true
            };
        } catch {
            return {
                isOk: false,
                message: "Failed to create account"
            };
        }
    },

    async resetPassword(email) {
        try {
            // Send request
            console.log(email);

            return {
                isOk: true
            };
        } catch {
            return {
                isOk: false,
                message: "Failed to reset password"
            };
        }
    }
};
