export default {

    version: '000000003',
    language: 'de',

    googleApi: 'AIzaSyBoGEsyYZ30VYxAm7j0vQFS1kD43s-WhlM',

    sessionToken: null,
    compId: null,
    userId: null,
    userGroupId: null,
    userIdent: null,
    serviceProviderId: null,
    isAdmin: false,
    dashboardReportId: null,

    menu: null,

    // host: document.location.origin.includes('thor') ? 'http://thor' : 'https://reptoolv2.mo-apps.de/',
    // apiHost: document.location.origin.includes('thor') ? 'http://thor/api/' : 'https://reptoolv2.mo-apps.de/api/',
    host: document.location.origin.includes('tt-dev.mo-dev.de') || document.location.origin.includes('localhost') || document.location.origin.includes('https://winteraktion.halvar-it.de') ? 'https://tt-dev.mo-dev.de/' : 'https://tt-portal.mo-dev.de/',
    apiHost: document.location.origin.includes('tt-dev.mo-dev.de') || document.location.origin.includes('localhost') || document.location.origin.includes('https://winteraktion.halvar-it.de') ? 'https://tt-dev.mo-dev.de/api/' : 'https://tt-portal.mo-dev.de/api/'

};

