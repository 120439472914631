import auth from "./auth";
import {createRouter, createWebHashHistory} from "vue-router";

import defaultLayout from "./layouts/side-nav-inner-toolbar";
import simpleLayout from "./layouts/single-card";

import appConfig from "@/app-config";

import {loadMessages, locale} from "devextreme/localization";
import deMessages from "devextreme/localization/messages/de.json";
import translations from "@/global_translations.json";

function loadView(view) {
    return () => import (/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

function addRouteFromMenuItem(items) {
    if (items)
        items.forEach((item) => {
            if (item.path && item.path !== '') {
                router.addRoute({
                    component: loadView(item.path.replace('/', '')),
                    name: item.path.replace('/', ''),
                    path: item.path,
                    meta: {
                        requiresAuth: true,
                        layout: defaultLayout,
                    }
                });
            }
            if (item.items) addRouteFromMenuItem(item.items);
        });
}

loadMessages(deMessages);
loadMessages(translations);
locale(appConfig.language);

let version = localStorage.getItem('version');
if (version !== appConfig.version) {
    let sessionToken = localStorage.getItem('sessionToken')
    localStorage.clear();
    localStorage.setItem('sessionToken', sessionToken);
    localStorage.setItem('version', appConfig.version);
}

await auth.authenticate();

let home = 'home';

if (location.host.includes('winteraktion.'))
    home = 'wa24-start';

const router = new createRouter({
    routes: [
        {
            path: "/",
            redirect: home
        },

        {
            path: "/:pathMatch(.*)*",
            redirect: home
        },

        {
            path: "/home",
            name: "home",
            meta: {
                requiresAuth: true,
                layout: defaultLayout
            },
            component: loadView("home/home")
        },
        {
            path: "/auth",
            name: "login-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Anmeldung"
            },
            component: loadView("auth/login-form")
        },
        {
            path: "/wa24-start",
            name: "wa24-start",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "wa24-start"
            },
            component: loadView("schulte/wa24-start")
        },
        {
            path: "/wa24-zip",
            name: "wa24-zip",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "wa24-zip"
            },
            component: loadView("schulte/wa24-zip")
        },
        {
            path: "/wa24-manufacturer",
            name: "wa24-manufacturer",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "wa24-zip"
            },
            component: loadView("schulte/wa24-manufacturer")
        },
        {
            path: "/wa24-model",
            name: "wa24-model",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "wa24-model"
            },
            component: loadView("schulte/wa24-model")
        },
        {
            path: "/wa24-service",
            name: "wa24-service",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "wa24-service"
            },
            component: loadView("schulte/wa24-service")
        },
        {
            path: "/wa24-contact",
            name: "wa24-contact",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "wa24-contact"
            },
            component: loadView("schulte/wa24-contact")
        },
        {
            path: "/wa24-success",
            name: "wa24-success",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "wa24-success"
            },
            component: loadView("schulte/wa24-success")
        },
        {
            path: "/wa24-address",
            name: "wa24-address",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "wa24-address"
            },
            component: loadView("schulte/wa24-address")
        },
        {
            path: "/wa24-compare",
            name: "wa24-compare",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "wa24-compare"
            },
            component: loadView("schulte/wa24-compare")
        },

        {
            path: "/wa24-destination",
            name: "wa24-destination",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "wa24-destination"
            },
            component: loadView("schulte/wa24-destination")
        },
        {
            path: "/wa24-invoice",
            name: "wa24-invoice",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "wa24-invoice"
            },
            component: loadView("schulte/wa24-invoice")
        },
        {
            path: "/wa24-pickup",
            name: "wa24-pickup",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "wa24-pickup"
            },
            component: loadView("schulte/wa24-pickup")
        },
        {
            path: "/wa24-preview",
            name: "wa24-preview",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "wa24-preview"
            },
            component: loadView("schulte/wa24-preview")
        },
        {
            path: "/wa24-finished",
            name: "wa24-finished",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "wa24-finished"
            },
            component: loadView("schulte/wa24-finished")
        },
    ],
    history: createWebHashHistory()
});

if (appConfig.menu)
    addRouteFromMenuItem(appConfig.menu);

router.beforeEach((to, from, next) => {

    if (from.name === 'login-form')
        addRouteFromMenuItem(appConfig.menu);

    if (to.name === "login-form" && auth.loggedIn()) {
        next({name: "home"});
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!auth.loggedIn()) {
            next({
                name: "login-form",
                query: {redirect: to.fullPath}
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
