import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp }  from "vue";
import router from "./router";
import themes from "devextreme/ui/themes";

import App from "./App";
import appInfo from "./app-info";

import { licenseKey } from './devextreme-license';
import Config from "devextreme/core/config";

import DevExpress from "devextreme/ui/themes";
// Set timezone

Config({
    licenseKey,
    defaultForceIsoDateParsing: true,
    forceIsoDateParsing: true,
    // editorStylingMode: 'outlined',
});

console.log(DevExpress.current());

themes.initialized(() => {

    const app = createApp(App);
    app.use(router);
    app.config.globalProperties.$appInfo = appInfo;
    app.mount('#app');
});


