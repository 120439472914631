<template>
    <dx-scroll-view height="100%" width="100%" class="single-card">
        <div class="dx-card content">
            <slot />
        </div>
    </dx-scroll-view>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";

import { useRoute } from 'vue-router';
import { watch, ref } from 'vue';

export default {
  components: {
    DxScrollView
  },
  setup() {
    const route = useRoute();

    const title = ref(route.meta.title);
    const description = ref("");

    watch(() => route.path,
     () => {
        title.value = route.meta.title;
        description.value = route.meta.description;
     }
    )
    return {
      title,
      description
    }
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.single-card {
    width: 100%;
    height: 100%;

    .dx-card {
        width: 100%;
        margin: auto auto;
        //padding: 40px;
        flex-grow: 0;

        .screen-x-small & {
            width: 100%;
            height: 100%;
            border-radius: 0;
            box-shadow: none;
            margin: 0;
            border: 0;
            flex-grow: 1;
        }

        .header {
            margin-bottom: 30px;
        }
    }
}
</style>
